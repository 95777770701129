import React from "react";
import Link from "next/link";
import { getAssets } from "@unisearch/helpers";
import { ReactSVG } from "react-svg";
import styles from "./custom404page.module.scss";

export const Custom404Page = ({ link = true }) => {
  return (
    <section className={styles.section_hero}>
      <div className="container">
        <div className={styles.wrapper}>
          <ReactSVG
            className={styles.illustration}
            src={getAssets("assets/images/custom404.svg")}
          />
          <p>
            We can’t find the page that <br /> you’re looking for.
          </p>
          {link ? (
            <Link legacyBehavior href="/">
              <a className="btn btn-secondary btn-bg">Back to Home</a>
            </Link>
          ) : null}
        </div>
      </div>
    </section>
  );
};

export default Custom404Page;
