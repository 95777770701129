import React from "react";
import { LayoutMain } from "@unisearch/theme/layout";
import { Custom404Page } from "@modules/study/custom404";

const Custom404 = () => {
  return (
    <LayoutMain
      title="404 Page Not Found"
      metaUrl={`${process.env.NEXT_PUBLIC_URL}/404`}
    >
      <Custom404Page />
    </LayoutMain>
  );
};

export default Custom404;
